






























































































import EventCard from '@/components/EventCard.vue';
import RegistrationFullFormDialog from '@/components/forms/RegistrationFullFormDialog.vue';
import Loading from '@/components/utils/Loading.vue';
import Commons from '@/mixins/Commons';
import Filters from '@/mixins/Filters';
import { registrationState } from '@/store/modules/registration';
import { LocationResponse, StudyOptionTypeResponse, TranslationValueTextFunction } from '@/types';
import { Category, Company, Language, StudyOption } from '@/types/enums';
import { GroupResponse } from '@/types/groups';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
    components: { RegistrationFullFormDialog, Loading, EventCard }
})
export default class SkyAutokoolGroups extends Mixins(Commons, Filters) {

    private selectedEvent: GroupResponse | null = null;
    private readonly studyOption: StudyOption = StudyOption.GROUP;
    private readonly company: Company = Company.SKYAUTOKOOL;

    get groups(): GroupResponse[] {
        return registrationState.skyAutokoolGroups;
    }

    get locations(): LocationResponse[] {
        return registrationState.locations.filter((location: LocationResponse) => location.appliedStudyOptions.includes(StudyOption.GROUP));
    }

    get filteredGroups(): GroupResponse[] {
        return this.groups.filter((group: GroupResponse) => {
            if (this.filters.language && group.language && this.filters.language !== group.language) {
                return false;
            }
            if (this.filters.locationId && group.location && this.filters.locationId !== group.location.id) {
                return false;
            }
            if (this.filters.category && group.category && this.filters.category !== group.category) {
                return false;
            }
            if (this.filters.studyOptionTypeId && group.type) {
                const selectedStudyOptionTypeIds: number[] = registrationState.unionWithStudyOptionTypesById(this.filters.studyOptionTypeId)
                    .map((studyOptionType: StudyOptionTypeResponse) => {
                        return studyOptionType.id;
                    });
                selectedStudyOptionTypeIds.push(this.filters.studyOptionTypeId);

                if (!selectedStudyOptionTypeIds.includes(group.type.id)) {
                    return false;
                }
            }
            return true;
        });
    }

    mounted() {
        registrationState.getSkyAutokoolGroups();
    }

    private isLanguageDisabled(item: TranslationValueTextFunction): boolean {
        const language: Language = item.value as Language;
        // studyOptionTypeId: 1 - В классе
        return language === Language.EST && this.filters.studyOptionTypeId === 1;
    }

    private isTypeDisabled(studyOptionType: StudyOptionTypeResponse): boolean {
        // studyOptionTypeId: 1 - В классе
        // locationId: 3 - Kesklinn
        return studyOptionType.id === 1
            && (this.filters.category === Category.A || this.filters.category === Category.A1 || this.filters.category === Category.A2 || this.filters.category === Category.AM || this.filters.category === Category.LS
                || this.filters.language === Language.EST
                || this.filters.locationId === 3);
    }

    private isCategoryDisabled(item: TranslationValueTextFunction): boolean {
        const category: Category = item.value as Category;
        // studyOptionTypeId: 1 - В классе
        return (category === Category.A || category === Category.A1 || category === Category.A2 || category === Category.AM || category === Category.LS)
            && this.filters.studyOptionTypeId === 1;
    }

    private isLocationDisabled(location: LocationResponse): boolean {
        // studyOptionTypeId: 1 - В классе
        // locationId: 3 - Kesklinn
        return location.id === 3 && this.filters.studyOptionTypeId === 1;
    }

    private openRegistrationFormDialog(selectedEvent: GroupResponse) {
        this.selectedEvent = selectedEvent;
        this.dialog = true;
    }
}
