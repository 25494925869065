import i18n from '@/config/i18n';
import { TranslationValueTextFunction } from '@/types';
import { Category, Language } from '@/types/enums';

export const languages: TranslationValueTextFunction[] = [
    { value: Language.EST, text: () => i18n.t('language.estonian') },
    { value: Language.RUS, text: () => i18n.t('language.russian')  },
    { value: Language.ENG, text: () => i18n.t('language.english')  }
];

export const categories: TranslationValueTextFunction[] = [
    { value: Category.B,  text: () => i18n.t('category.B')  },
    { value: Category.A,  text: () => i18n.t('category.A')  },
    { value: Category.A1, text: () => i18n.t('category.A1') },
    { value: Category.A2, text: () => i18n.t('category.A2') },
    { value: Category.AM, text: () => i18n.t('category.AM') },
    { value: Category.LS, text: () => i18n.t('category.LS') }
];

export const transmissions: TranslationValueTextFunction[] = [
    { value: 'MANUAL',                 text: () => i18n.t('transmission.manual')               },
    { value: 'AUTOMAT',                text: () => i18n.t('transmission.automat')              },
    { value: 'MANUAL_DRIVING_AUTOMAT', text: () => i18n.t('transmission.manual-driving-automat') }
];
