


















import CompanySpecific from '@/mixins/CompanySpecific';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class NoSuitableFound extends Mixins(CompanySpecific) {
}
