









































































import CompanySpecific from '@/mixins/CompanySpecific';
import DateUtils from '@/mixins/DateUtils';
import RegistrationFormMixin from '@/mixins/RegistrationFormMixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component
export default class RegistrationFormContainer extends Mixins(RegistrationFormMixin, CompanySpecific, DateUtils) {

    @Prop({ type: Function, required: true })
    protected readonly submit!: () => void;

    @Prop({ type: Function, required: true })
    protected readonly reset!: () => void;
}
