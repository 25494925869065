











































































import EventCard from '@/components/EventCard.vue';
import RegistrationToDrivingLessonFormDialog from '@/components/forms/RegistrationToDrivingLessonFormDialog.vue';
import Loading from '@/components/utils/Loading.vue';
import Commons from '@/mixins/Commons';
import Filters from '@/mixins/Filters';
import { registrationState } from '@/store/modules/registration';
import { LocationResponse } from '@/types';
import { DrivingLessonResponse } from '@/types/drivingLessons';
import { Company, StudyOption } from '@/types/enums';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
    components: { RegistrationToDrivingLessonFormDialog, Loading, EventCard }
})
export default class DrivingLessons extends Mixins(Commons, Filters) {

    private selectedEvent: DrivingLessonResponse | null = null;
    private readonly studyOption: StudyOption = StudyOption.DRIVING_LESSON;
    private readonly company: Company = Company.SKYAUTOKOOL;

    get drivingLessons(): DrivingLessonResponse[] {
        return registrationState.drivingLessons;
    }

    get locations(): LocationResponse[] {
        return registrationState.locations.filter((location: LocationResponse) => location.appliedStudyOptions.includes(StudyOption.DRIVING_LESSON));
    }

    get isLoading(): boolean {
        return registrationState.isLoading;
    }

    get filteredDrivingLessons(): DrivingLessonResponse[] {
        return this.drivingLessons.filter((drivingLesson: DrivingLessonResponse) => {
            if (this.filters.language && drivingLesson.language && this.filters.language !== drivingLesson.language) {
                return false;
            }
            if (this.filters.locationId && drivingLesson.location && this.filters.locationId !== drivingLesson.location.id) {
                return false;
            }
            if (this.filters.category && drivingLesson.category && this.filters.category !== drivingLesson.category) {
                return false;
            }
            return true;
        });
    }

    mounted() {
        registrationState.getDrivingLessons();
    }

    private openRegistrationFormDialog(selectedEvent: DrivingLessonResponse) {
        this.selectedEvent = selectedEvent;
        this.dialog = true;
    }
}
