

































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Confirm extends Vue {

    @Prop({ type: String, required: true })
    private readonly message!: string;

    private value: boolean = false;

    public choose(value: boolean) {
        this.value = value;
        this.$destroy();
    }
}
