























import { saveRegistrationByPersonalCode } from '@/api/registration';
import RegistrationFormContainer from '@/components/forms/RegistrationFormContainer.vue';
import DatePicker from '@/components/utils/DatePicker.vue';
import CompanySpecific from '@/mixins/CompanySpecific';
import DateUtils from '@/mixins/DateUtils';
import RegistrationFormMixin from '@/mixins/RegistrationFormMixin';
import { personalCodeRegex } from '@/utils/formValidators';
import axios from 'axios';
import { Component, Mixins } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { maxLength, required } from 'vuelidate/lib/validators';
import { Validate } from 'vuelidate-property-decorators';

@Component({
    components: { RegistrationFormContainer, DatePicker },
    mixins: [validationMixin]
})
export default class RegistrationByPersonalCodeFormDialog extends Mixins(RegistrationFormMixin, CompanySpecific, DateUtils) {

    @Validate({
        required,
        maxLength: maxLength(255),
        personalCodeRegex
    })
    private personalCode: string | null = null;

    get personalCodeErrorMessages(): string[] {
        if (!this.$v.personalCode?.$dirty) return [];
        if (!this.$v.personalCode?.required) return ['Личный код не может быть пустым'];
        if (!this.$v.personalCode?.maxLength) return ['Личный код может быть максимум 255 символов'];
        if (!this.$v.personalCode?.personalCodeRegex) return ['Неверный личный код'];
        return [];
    }

    private async submit() {
        this.$v.$touch();
        if (this.$v.$invalid || this.isSubmitting) {
            return;
        }

        this.isSubmitting = true;
        try {
            await saveRegistrationByPersonalCode({
                personalCode: this.personalCode!,
                studyOption: this.studyOption,
                studyOptionId: this.event!.id as number
            });

            this.$gtm.trackEvent({ event: 'reg_autokool' });
            this.$notify({
                text: this.$t('form.success') as string,
                type: 'success'
            });
            this.reset();
        } catch (error: unknown) {
            if (axios.isAxiosError(error) && error.response?.data?.showFullForm) {
                this.$emit('showFullForm', this.personalCode!);
            }
        } finally {
            this.isSubmitting = false;
        }
    }

    private reset() {
        this.personalCode = null;
        this.$v.$reset();

        this.dialog = false;
        this.$emit('input', false);
    }
}
