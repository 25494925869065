import en from '@/locales/en.json';
import et from '@/locales/et.json';
import ru from '@/locales/ru.json';
import { TranslationValueTextFunction } from '@/types';
import { getLocale } from '@/utils/localStorageUtils';
import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

function detectBrowserLocale(): Locale | undefined {
    const browserDefaultLocale: string = window.navigator.language;
    const languageCode: string = browserDefaultLocale.split('-')[0];
    return parseLocale(languageCode);
}

function parseLocale(localeToParse?: string | null): Locale | undefined {
    if (!localeToParse) return undefined;
    return Object.values(Locale).find((locale: string) => locale === localeToParse);
}

export enum Locale {
    ET = 'et',
    RU = 'ru',
    EN = 'en'
}

export const messages: LocaleMessages = {
    [Locale.ET]: et,
    [Locale.RU]: ru,
    [Locale.EN]: en
};

export const defaultLocale: Locale = Locale.ET;

export function determineLocale() {
    return parseLocale(getLocale()) ?? detectBrowserLocale() ?? defaultLocale;
}

const i18n: VueI18n = new VueI18n({
    messages: messages,
    locale: determineLocale(),
    fallbackLocale: defaultLocale
});

export const locales: TranslationValueTextFunction[] = [
    { value: Locale.ET, text: () => i18n.t('language.estonian') },
    { value: Locale.RU, text: () => i18n.t('language.russian')  },
    { value: Locale.EN, text: () => i18n.t('language.english')  }
];

export default i18n;
