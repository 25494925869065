/* eslint-disable @typescript-eslint/no-explicit-any */
import Confirm from '@/components/utils/Confirm.vue';
import Vue, { VueConstructor } from 'vue';
import Vuetify from 'vuetify';

type ConfirmValue = Vue & {
    value: boolean;
};

type PluginOptions = {
    vuetify: Vuetify;
};

function ConfirmPlugin(Vue: VueConstructor, options: PluginOptions) {
    if ((ConfirmPlugin as any).installed) return;
    (ConfirmPlugin as any).installed = true;

    const vuetify: Vuetify = options.vuetify;
    const ConfirmConstructor: VueConstructor = Vue.extend({ vuetify }).extend(Confirm);

    async function createConfirmDialog(message: string): Promise<boolean> {
        const container: Element = document.querySelector('[data-app=true]') ?? document.body;

        return new Promise((resolve: (value: boolean | PromiseLike<boolean>) => void) => {
            const component: ConfirmValue = new ConfirmConstructor({
                propsData: {
                    message: message
                },
                destroyed: () => {
                    container.removeChild(component.$el);
                    resolve(component.value);
                }
            });
            container.appendChild(component.$mount().$el);
        });
    }

    Vue.prototype['$confirm'] = createConfirmDialog;
}

if (typeof window !== 'undefined' && !!window.Vue) {
    window.Vue.use(ConfirmPlugin);
}

export default ConfirmPlugin;
