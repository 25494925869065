export enum Company {
    SKYAUTOKOOL = 'sky',
    LOPPASTME = 'loppastme',
    SMART_AUTOKOOL = 'smart'
}

export enum StudyOption {
    GROUP = 'GROUP',
    COURSE = 'COURSE',
    DRIVING_LESSON = 'DRIVING_LESSON',
    EXAM = 'EXAM'
}

export enum Language {
    RUS = 'RUS',
    EST = 'EST',
    ENG = 'ENG'
}

export enum Category {
    A = 'A',
    A1 = 'A1',
    A2 = 'A2',
    AM = 'AM',
    B = 'B',
    LS = 'LS'
}

export enum Transmission {
    MANUAL = 'MANUAL',
    AUTOMAT = 'AUTOMAT',
    MANUAL_DRIVING_AUTOMAT = 'MANUAL_DRIVING_AUTOMAT'
}
