











































































import EventCard from '@/components/EventCard.vue';
import RegistrationByPersonalCodeFormDialog from '@/components/forms/RegistrationByPersonalCodeFormDialog.vue';
import Loading from '@/components/utils/Loading.vue';
import Commons from '@/mixins/Commons';
import Filters from '@/mixins/Filters';
import { registrationState } from '@/store/modules/registration';
import { LocationResponse } from '@/types';
import { Company, StudyOption } from '@/types/enums';
import { ExamResponse } from '@/types/exams';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
    components: { RegistrationByPersonalCodeFormDialog, Loading, EventCard }
})
export default class Exams extends Mixins(Commons, Filters) {

    private selectedEvent: ExamResponse | null = null;
    private readonly studyOption: StudyOption = StudyOption.EXAM;
    private readonly company: Company = Company.SKYAUTOKOOL;

    get exams(): ExamResponse[] {
        return registrationState.exams;
    }

    get locations(): LocationResponse[] {
        return registrationState.locations.filter((location: LocationResponse) => location.appliedStudyOptions.includes(StudyOption.EXAM));
    }

    get filteredExams(): ExamResponse[] {
        return this.exams.filter((exam: ExamResponse) => {
            if (this.filters.language && exam.language && this.filters.language !== exam.language) {
                return false;
            }
            if (this.filters.locationId && exam.location && this.filters.locationId !== exam.location.id) {
                return false;
            }
            if (this.filters.category && exam.category && this.filters.category !== exam.category) {
                return false;
            }
            return true;
        });
    }

    mounted() {
        registrationState.getExams();
    }

    private openRegistrationFormDialog(selectedEvent: ExamResponse) {
        this.selectedEvent = selectedEvent;
        this.dialog = true;
    }
}
