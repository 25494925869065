/* eslint-disable simple-import-sort/imports */
import Vue, { CreateElement } from 'vue';
import App from '@/App.vue';
import router from '@/config/router';
import store from '@/store';
import i18n from '@/config/i18n';
import vuetify from '@/plugins/vuetify';
import ConfirmPlugin from '@/plugins/confirm';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import VueGtm from 'vue-gtm';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/main.scss';

Vue.config.productionTip = false;

Vue.use(ConfirmPlugin, { vuetify });
Vue.use(Notifications);
Vue.use(Vuelidate);

Vue.use(VueGtm, {
    id: 'GTM-NMZPG97',
    vueRouter: router,
    enabled: process.env.NODE_ENV === 'production'
});

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h: CreateElement) => h(App)
}).$mount('#app');
