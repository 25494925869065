import axios from '@/config/axios';
import {
    LocationResponse,
    RegistrationByPersonalCodeRequest,
    RegistrationFullFormRequest,
    RegistrationToDrivingLessonRequest,
    StudyOptionTypeResponse
} from '@/types';
import { CourseResponse } from '@/types/courses';
import { DrivingLessonResponse } from '@/types/drivingLessons';
import { ExamResponse } from '@/types/exams';
import { GroupResponse } from '@/types/groups';
import { AxiosResponse } from 'axios';

export async function getSkyAutokoolGroups(): Promise<AxiosResponse<GroupResponse[]>> {
    return axios.get('/groups/skyautokool');
}

export async function getLoppastmeGroups(): Promise<AxiosResponse<GroupResponse[]>> {
    return axios.get('/groups/loppastme');
}

export async function getCourses(): Promise<AxiosResponse<CourseResponse[]>> {
    return axios.get('/courses');
}

export async function getDrivingLessons(): Promise<AxiosResponse<DrivingLessonResponse[]>> {
    return axios.get('/driving-lessons');
}

export async function getExams(): Promise<AxiosResponse<ExamResponse[]>> {
    return axios.get('/exams');
}

export async function getStudyOptionTypes(): Promise<AxiosResponse<StudyOptionTypeResponse[]>> {
    return axios.get('/types');
}

export async function getLocations(): Promise<AxiosResponse<LocationResponse[]>> {
    return axios.get('/locations');
}

export async function saveRegistrationFullForm(registrationFullFormRequest: RegistrationFullFormRequest): Promise<AxiosResponse<void>> {
    return axios.post('/save/full', registrationFullFormRequest);
}

export async function saveRegistrationByPersonalCode(registrationByPersonalCodeRequest: RegistrationByPersonalCodeRequest): Promise<AxiosResponse<void>> {
    return axios.post('/save/personal-code', registrationByPersonalCodeRequest);
}

export async function saveRegistrationToDrivingLesson(registrationToDrivingLessonRequest: RegistrationToDrivingLessonRequest): Promise<AxiosResponse<void>> {
    return axios.post('/save/driving-lesson', registrationToDrivingLessonRequest);
}
