



































import DateUtils from '@/mixins/DateUtils';
import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';

@Component
export default class DatePicker extends Mixins(DateUtils) {

    @VModel({ required: true })
    private date: string | null;

    @Prop({ default: 'Дата' })
    private readonly label: string;

    @Prop({ default: () => [] })
    private readonly errorMessages: string[];

    @Prop({ default: false })
    private readonly disabled: boolean;

    @Prop({ default: false })
    private readonly hideDetails: boolean;

    @Prop({ default: false })
    private readonly outlined: boolean;

    @Prop({ default: false })
    private readonly dense: boolean;

    private menu: boolean = false;
    private dateFormatted: string | null = null;

    @Watch('date', {
        immediate: true
    })
    private dateChanged() {
        this.dateFormatted = this.formatIsoDate(this.date);
    }

    @Watch('dateFormatted', {
        immediate: true
    })
    private dateFormattedChanged() {
        if (!this.dateFormatted) {
            this.date = null;
        }
    }
}
