





























































import EventCard from '@/components/EventCard.vue';
import RegistrationFullFormDialog from '@/components/forms/RegistrationFullFormDialog.vue';
import Loading from '@/components/utils/Loading.vue';
import Commons from '@/mixins/Commons';
import FilterUtils from '@/mixins/Filters';
import { registrationState } from '@/store/modules/registration';
import { LocationResponse } from '@/types';
import { Company, StudyOption } from '@/types/enums';
import { GroupResponse } from '@/types/groups';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
    components: { RegistrationFullFormDialog, Loading, EventCard }
})
export default class LoppastmeGroups extends Mixins(Commons, FilterUtils) {

    private selectedEvent: GroupResponse | null = null;
    private readonly studyOption: StudyOption = StudyOption.GROUP;
    private readonly company: Company = Company.LOPPASTME;

    get groups(): GroupResponse[] {
        return registrationState.loppastmeGroups;
    }

    get locations(): LocationResponse[] {
        return registrationState.locations.filter((location: LocationResponse) => location.appliedStudyOptions.includes(StudyOption.GROUP));
    }

    get filteredGroups(): GroupResponse[] {
        return this.groups.filter((group: GroupResponse) => {
            if (this.filters.language && group.language && this.filters.language !== group.language) {
                return false;
            }
            if (this.filters.locationId && group.location && this.filters.locationId !== group.location.id) {
                return false;
            }
            return true;
        });
    }

    mounted() {
        registrationState.getLoppastmeGroups();
    }

    private openRegistrationFormDialog(selectedEvent: GroupResponse) {
        this.selectedEvent = selectedEvent;
        this.dialog = true;
    }
}
