import { categories, languages } from '@/config/constants';
import { registrationState } from '@/store/modules/registration';
import { StudyOptionTypeResponse, TranslationValueTextFunction } from '@/types';
import { Company, StudyOption } from '@/types/enums';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Commons extends Vue {

    protected dialog: boolean = false;
    protected readonly StudyOption: typeof StudyOption = StudyOption;
    protected readonly Company: typeof Company = Company;

    get groupStudyOptionTypes(): StudyOptionTypeResponse[] {
        return registrationState.groupStudyOptionTypes;
    }

    get courseStudyOptionTypes(): StudyOptionTypeResponse[] {
        return registrationState.courseStudyOptionTypes;
    }

    get languages(): TranslationValueTextFunction[] {
        return languages;
    }

    get categories(): TranslationValueTextFunction[] {
        return categories;
    }

    get studyOptionTypeLabelByLocale(): string {
        switch (this.$root.$i18n.locale) {
            case 'ru':
                return 'labelRus';
            case 'et':
                return 'labelEst';
            case 'en':
                return 'labelEng';
            default :
                return 'labelEst';
        }
    }

    public translate(item: TranslationValueTextFunction) {
        return item.text();
    }
}
