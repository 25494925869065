import { transmissions } from '@/config/constants';
import { TranslationValueTextFunction } from '@/types';
import { CourseResponse } from '@/types/courses';
import { DrivingLessonResponse } from '@/types/drivingLessons';
import { Category, StudyOption } from '@/types/enums';
import { ExamResponse } from '@/types/exams';
import { GroupResponse } from '@/types/groups';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component
export default class RegistrationFormMixin extends Vue {

    @VModel({ type: Boolean, required: true })
    protected dialog!: boolean;

    @Prop({ required: true })
    protected readonly event: GroupResponse | CourseResponse | DrivingLessonResponse | ExamResponse | null;

    @Prop({ type: String, required: true })
    protected readonly studyOption!: StudyOption;

    protected isSubmitting: boolean = false;

    get transmissions() {
        if (this.event && 'category' in this.event && this.event.category === Category.LS) {
            return transmissions;
        }
        return transmissions.filter((transmission: TranslationValueTextFunction) => transmission.value === 'MANUAL' || transmission.value === 'AUTOMAT');
    }
}
