var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-main',{staticClass:"grey lighten-3"},[_c('v-container',[_c('v-row',[(_vm.isSkyAutokool)?_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"2"}},[_c('v-sheet',{staticClass:"desktop-menu-margin",attrs:{"rounded":"lg"}},[_c('v-list',{attrs:{"color":"transparent"}},_vm._l((_vm.menuRoutes),function(menuRoute){return _c('v-list-item',{key:menuRoute.routeName,attrs:{"to":{ name: menuRoute.routeName },"active-class":"primary white--text","link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(menuRoute.text))])],1)],1)}),1)],1)],1):_vm._e(),_c('v-col',[_c('v-toolbar',{attrs:{"flat":"","rounded":"lg"}},[_c('v-toolbar-title',{staticClass:"text-h4 hidden-sm-and-down"},[_vm._v(_vm._s(_vm.$t('calendar')))]),(_vm.isSkyAutokool)?_c('v-toolbar-items',{staticClass:"hidden-md-and-up"},_vm._l((_vm.menuRoutes),function(menuRoute){return _c('v-btn',{key:menuRoute.routeName,attrs:{"to":{ name: menuRoute.routeName },"active-class":"primary white--text","text":"","x-small":""}},[_vm._v(" "+_vm._s(menuRoute.text)+" ")])}),1):_vm._e(),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[(_vm.isSkyAutokool)?_c('v-btn',{staticClass:"my-3 mr-5",attrs:{"color":_vm.color,"max-height":"38","href":"https://skyautokool.ee"}},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.back-to.skyautokool')))])]):_vm._e(),(_vm.isLoppatme)?_c('v-btn',{staticClass:"my-3 mr-5",attrs:{"color":_vm.color,"max-height":"38","href":"https://libedasoit.ee"}},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.back-to.loppastme')))])]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-2",attrs:{"max-height":"48","text":""}},Object.assign({}, tooltip, menu)),[_c('v-img',{attrs:{"src":require(("@/assets/img/" + (_vm.$root.$i18n.locale) + ".svg")),"width":"36"}}),_c('v-icon',{staticClass:"ml-3 mr-n3"},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('language.choose')))])])]}}])},[_c('v-list',_vm._l((_vm.locales),function(locale){return _c('v-list-item',{key:locale.value,staticClass:"clickable",on:{"click":function($event){return _vm.switchLocale(locale.value)}}},[_c('v-img',{staticClass:"mr-4",attrs:{"src":require(("@/assets/img/" + (locale.value) + ".svg")),"width":"24"}}),_c('v-list-item-title',[_vm._v(_vm._s(locale.text()))])],1)}),1)],1)],1),_c('v-menu',{attrs:{"bottom":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({staticClass:"hidden-md-and-up"},'v-app-bar-nav-icon',attr,false),on))]}}])},[_c('v-list',{attrs:{"dense":""}},[(_vm.isSkyAutokool)?_c('v-list-item',{attrs:{"href":"https://skyautokool.ee","link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menu.back-to.skyautokool')))])],1)],1):_vm._e(),(_vm.isLoppatme)?_c('v-list-item',{attrs:{"href":"https://libedasoit.ee","link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menu.back-to.loppastme')))])],1)],1):_vm._e(),_c('v-divider',{class:!_vm.isSkyAutokool ? ['my-2'] : []}),_vm._l((_vm.locales),function(locale){return _c('v-list-item',{key:locale.value,staticClass:"clickable",on:{"click":function($event){return _vm.switchLocale(locale.value)}}},[_c('v-img',{staticClass:"mr-4",attrs:{"src":require(("@/assets/img/" + (locale.value) + ".svg")),"width":"24"}}),_c('v-list-item-title',[_vm._v(_vm._s(locale.text()))])],1)})],2),_c('v-divider')],1)],1),_c('no-suitable-found',{staticClass:"my-5",attrs:{"company":_vm.company}}),_c('v-sheet',{staticClass:"pa-5",attrs:{"min-height":"70vh","rounded":"lg"}},[_c('router-view')],1)],1)],1)],1)],1),_c('notification')],1)}
var staticRenderFns = []

export { render, staticRenderFns }