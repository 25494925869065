import { determineLocale, Locale } from '@/config/i18n';
import MainLayout from '@/layouts/MainLayout.vue';
import { Company } from '@/types/enums';
import { setLocale } from '@/utils/localStorageUtils';
import Courses from '@/views/Courses.vue';
import DrivingLessons from '@/views/DrivingLessons.vue';
import Exams from '@/views/Exams.vue';
import LoppastmeGroups from '@/views/LoppastmeGroups.vue';
import SkyAutokoolGroups from '@/views/SkyAutokoolGroups.vue';
import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: {
            name: 'skyautokool-groups',
            params: {
                locale: determineLocale()
            }
        }
    },
    {
        path: '/sky',
        redirect: {
            name: 'skyautokool-groups',
            params: {
                locale: determineLocale()
            }
        }
    },
    {
        path: '/loppastme',
        redirect: {
            name: 'loppastme-groups',
            params: {
                locale: determineLocale()
            }
        }
    },
    {
        path: '/:locale/sky',
        component: MainLayout,
        props: {
            company: Company.SKYAUTOKOOL
        },
        redirect: '/:locale/sky/groups',
        children: [
            {
                path: 'groups',
                name: 'skyautokool-groups',
                component: SkyAutokoolGroups,
                props: true
            },
            {
                path: 'courses',
                name: 'courses',
                component: Courses,
                props: true
            },
            {
                path: 'driving-lessons',
                name: 'driving-lessons',
                component: DrivingLessons,
                props: true
            },
            {
                path: 'exams',
                name: 'exams',
                component: Exams,
                props: true
            },
            {
                path: '*',
                redirect: '/:locale/sky/groups'
            }
        ]
    },
    {
        path: '/:locale/loppastme',
        component: MainLayout,
        props: {
            company: Company.LOPPASTME
        },
        children: [
            {
                path: '/',
                name: 'loppastme-groups',
                component: LoppastmeGroups,
                props: true
            },
            {
                path: '*',
                redirect: '/:locale/loppastme'
            }
        ]
    },
    {
        path: '*',
        redirect: {
            name: 'skyautokool-groups',
            params: {
                locale: determineLocale()
            }
        }
    }
];

const router: VueRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
    if (to.params.locale === Locale.ET || to.params.locale === Locale.RU || to.params.locale === Locale.EN) {
        setLocale(to.params.locale);
        next();
    } else if (to.params.locale === 'est') {
        next({ name: <string | undefined>to.name, params: { locale: Locale.ET } });
    } else if (to.params.locale === 'rus') {
        next({ name: <string | undefined>to.name, params: { locale: Locale.RU } });
    } else if (to.params.locale === 'eng') {
        next({ name: <string | undefined>to.name, params: { locale: Locale.EN } });
    } else {
        console.log(to);
        next({ name: <string | undefined>to.name, params: { locale: determineLocale() } });
    }
});

export default router;
