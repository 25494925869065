






















































































import EventCard from '@/components/EventCard.vue';
import RegistrationByPersonalCodeFormDialog from '@/components/forms/RegistrationByPersonalCodeFormDialog.vue';
import RegistrationFullFormDialog from '@/components/forms/RegistrationFullFormDialog.vue';
import Loading from '@/components/utils/Loading.vue';
import Commons from '@/mixins/Commons';
import Filters from '@/mixins/Filters';
import { registrationState } from '@/store/modules/registration';
import { LocationResponse, StudyOptionTypeResponse } from '@/types';
import { CourseResponse } from '@/types/courses';
import { Company, StudyOption } from '@/types/enums';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
    components: { RegistrationFullFormDialog, RegistrationByPersonalCodeFormDialog, Loading, EventCard }
})
export default class Courses extends Mixins(Commons, Filters) {

    private selectedEvent: CourseResponse | null = null;
    private showFullForm: boolean = false;
    private personalCode: string | null;
    private readonly studyOption: StudyOption = StudyOption.COURSE;
    private readonly company: Company = Company.SKYAUTOKOOL;

    get courses(): CourseResponse[] {
        return registrationState.courses;
    }

    get locations(): LocationResponse[] {
        return registrationState.locations.filter((location: LocationResponse) => location.appliedStudyOptions.includes(StudyOption.COURSE));
    }

    get filteredCourses(): CourseResponse[] {
        return this.courses.filter((course: CourseResponse) => {
            if (this.filters.language && course.language && this.filters.language !== course.language) {
                return false;
            }
            if (this.filters.locationId && course.location && this.filters.locationId !== course.location.id) {
                return false;
            }
            if (this.filters.studyOptionTypeId && course.type) {
                const selectedStudyOptionTypeIds: number[] = registrationState.unionWithStudyOptionTypesById(this.filters.studyOptionTypeId)
                    .map((studyOptionType: StudyOptionTypeResponse) => {
                        return studyOptionType.id;
                    });
                selectedStudyOptionTypeIds.push(this.filters.studyOptionTypeId);

                if (!selectedStudyOptionTypeIds.includes(course.type.id)) {
                    return false;
                }
            }
            return true;
        });
    }

    mounted() {
        registrationState.getCourses();
    }

    private openRegistrationFormDialog(selectedEvent: CourseResponse) {
        this.selectedEvent = selectedEvent;
        this.dialog = true;
    }
}
