import store from '@/store';
import { EventFilters } from '@/types';
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface FiltersVuexState {
    filters: EventFilters | null;
}

@Module({
    name: 'filters',
    namespaced: true,
    dynamic: true,
    store
})
class Filters extends VuexModule implements FiltersVuexState {

    public filters: EventFilters | null = null;

    @Mutation
    public setFilters(filters: EventFilters) {
        this.filters = { ...filters };
    }
}

export const filtersState: Filters = getModule(Filters);
