import { getCourses, getDrivingLessons, getExams, getLocations, getLoppastmeGroups, getSkyAutokoolGroups, getStudyOptionTypes } from '@/api/registration';
import store from '@/store';
import { LocationResponse, StudyOptionTypeResponse } from '@/types';
import { CourseResponse } from '@/types/courses';
import { DrivingLessonResponse } from '@/types/drivingLessons';
import { StudyOption } from '@/types/enums';
import { ExamResponse } from '@/types/exams';
import { GroupResponse } from '@/types/groups';
import { AxiosResponse } from 'axios';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface RegistrationVuexState {
    skyAutokoolGroups: GroupResponse[];
    loppastmeGroups: GroupResponse[];
    courses: CourseResponse[];
    drivingLessons: DrivingLessonResponse[];
    exams: ExamResponse[];
    studyOptionTypes: StudyOptionTypeResponse[];
    locations: LocationResponse[];
    isLoading: boolean;
}

@Module({
    name: 'registration',
    namespaced: true,
    dynamic: true,
    store
})
class Registration extends VuexModule implements RegistrationVuexState {

    public skyAutokoolGroups: GroupResponse[] = [];
    public loppastmeGroups: GroupResponse[] = [];
    public courses: CourseResponse[] = [];
    public drivingLessons: DrivingLessonResponse[] = [];
    public exams: ExamResponse[] = [];
    public studyOptionTypes: StudyOptionTypeResponse[] = [];
    public locations: LocationResponse[] = [];

    public isLoading: boolean = false;

    get groupStudyOptionTypes(): StudyOptionTypeResponse[] {
        return this.studyOptionTypes.filter((studyOptionType: StudyOptionTypeResponse) => {
            return studyOptionType.studyOption === StudyOption.GROUP && !studyOptionType.unionWith;
        });
    }

    get courseStudyOptionTypes(): StudyOptionTypeResponse[] {
        return this.studyOptionTypes.filter((studyOptionType: StudyOptionTypeResponse) => {
            return studyOptionType.studyOption === StudyOption.COURSE && !studyOptionType.unionWith;
        });
    }

    get unionWithStudyOptionTypesById(): (studyOptionTypeId: number) => StudyOptionTypeResponse[] {
        return (studyOptionTypeId: number) => {
            return this.studyOptionTypes.filter((studyOptionType: StudyOptionTypeResponse) => {
                return studyOptionType.unionWith?.id === studyOptionTypeId;
            });
        };
    }

    @Mutation
    public setSkyAutokoolGroups(skyAutokoolGroups: GroupResponse[]) {
        this.skyAutokoolGroups = skyAutokoolGroups;
    }

    @Mutation
    public setlLoppastmeGroups(loppastmeGroups: GroupResponse[]) {
        this.loppastmeGroups = loppastmeGroups;
    }

    @Mutation
    public setCourses(courses: CourseResponse[]) {
        this.courses = courses;
    }

    @Mutation
    public setDrivingLessons(drivingLessons: DrivingLessonResponse[]) {
        this.drivingLessons = drivingLessons;
    }

    @Mutation
    public setExams(exams: ExamResponse[]) {
        this.exams = exams;
    }

    @Mutation
    public setStudyOptionTypes(studyOptionTypes: StudyOptionTypeResponse[]) {
        this.studyOptionTypes = studyOptionTypes;
    }

    @Mutation
    public setLocations(locations: LocationResponse[]) {
        this.locations = locations;
    }

    @Mutation
    public setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @Action
    public async getSkyAutokoolGroups() {
        this.setIsLoading(true);
        try {
            const response: AxiosResponse<GroupResponse[]> = await getSkyAutokoolGroups();
            this.setSkyAutokoolGroups(response.data);
            // eslint-disable-next-line no-empty
        } catch {
        } finally {
            this.setIsLoading(false);
        }
    }

    @Action
    public async getLoppastmeGroups() {
        this.setIsLoading(true);
        try {
            const response: AxiosResponse<GroupResponse[]> = await getLoppastmeGroups();
            this.setlLoppastmeGroups(response.data);
            // eslint-disable-next-line no-empty
        } catch {
        } finally {
            this.setIsLoading(false);
        }
    }

    @Action
    public async getCourses() {
        this.setIsLoading(true);
        try {
            const response: AxiosResponse<CourseResponse[]> = await getCourses();
            this.setCourses(response.data);
            // eslint-disable-next-line no-empty
        } catch {
        } finally {
            this.setIsLoading(false);
        }
    }

    @Action
    public async getDrivingLessons() {
        this.setIsLoading(true);
        try {
            const response: AxiosResponse<DrivingLessonResponse[]> = await getDrivingLessons();
            this.setDrivingLessons(response.data);
            // eslint-disable-next-line no-empty
        } catch {
        } finally {
            this.setIsLoading(false);
        }
    }

    @Action
    public async getExams() {
        this.setIsLoading(true);
        try {
            const response: AxiosResponse<ExamResponse[]> = await getExams();
            this.setExams(response.data);
            // eslint-disable-next-line no-empty
        } catch {
        } finally {
            this.setIsLoading(false);
        }
    }

    @Action
    public async getStudyOptionTypes() {
        try {
            const response: AxiosResponse<StudyOptionTypeResponse[]> = await getStudyOptionTypes();
            this.setStudyOptionTypes(response.data);
            // eslint-disable-next-line no-empty
        } catch {}
    }

    @Action
    public async getLocations() {
        try {
            const response: AxiosResponse<LocationResponse[]> = await getLocations();
            this.setLocations(response.data);
            // eslint-disable-next-line no-empty
        } catch {}
    }
}

export const registrationState: Registration = getModule(Registration);
