import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/src/locale/en';
import et from 'vuetify/src/locale/et';
import ru from 'vuetify/src/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { et, ru, en }
    },
    theme: {
        themes: {
            light: {
                primary: '0380E9',
                secondary: 'FFB302',
                accent: 'FCE32A'
            }
        }
    }
});
