import i18n from '@/config/i18n';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import Vue from 'vue';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_BACKEND_BASE}/registration`,
    headers: {
        common: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    }
});

axiosInstance.interceptors.response.use(
    async (response: AxiosResponse) => {
        return response;
    },
    // TODO: translations
    async (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
            Vue.notify({
                text: error.response?.data?.message ?? i18n.t('form.fail'),
                type: error.response?.data?.showFullForm ? 'warning' : 'error'
            });
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
