

































































































import DateUtils from '@/mixins/DateUtils';
import { CourseResponse } from '@/types/courses';
import { DrivingLessonResponse } from '@/types/drivingLessons';
import { ExamResponse } from '@/types/exams';
import { GroupResponse } from '@/types/groups';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component
export default class EventCard extends Mixins(DateUtils) {

    @Prop({ type: Object, required: true })
    private readonly event!: GroupResponse | CourseResponse | DrivingLessonResponse | ExamResponse;

    @Prop({ type: String, required: true })
    private readonly color!: string;

    @Prop({ type: String, default: 'white' })
    private readonly textColor!: string;

    private show: boolean = false;

    get iconSize(): number {
        return this.$vuetify.breakpoint.smAndUp ? 32 : 24;
    }
}
