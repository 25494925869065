













































































































































import NoSuitableFound from '@/components/NoSuitableFound.vue';
import Notification from '@/components/utils/Notification.vue';
import { determineLocale, Locale, locales } from '@/config/i18n';
import CompanySpecific from '@/mixins/CompanySpecific';
import { registrationState } from '@/store/modules/registration';
import { TranslationValueTextFunction } from '@/types';
import { setLocale } from '@/utils/localStorageUtils';
import { TranslateResult } from 'vue-i18n';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
    components: { NoSuitableFound, Notification }
})
export default class MainLayout extends Mixins(CompanySpecific) {

    get menuRoutes(): Record<string, string | TranslateResult>[] {
        return [
            { routeName: 'skyautokool-groups', text: this.$t('menu.groups')          },
            { routeName: 'courses',            text: this.$t('menu.courses')         },
            { routeName: 'driving-lessons',    text: this.$t('menu.driving-lessons') },
            { routeName: 'exams',          text: this.$t('menu.exams')       }
        ];
    }

    get locales(): TranslationValueTextFunction[] {
        return locales;
    }

    mounted() {
        this.setInitialLocale();
        registrationState.getStudyOptionTypes();
        registrationState.getLocations();
    }

    private setInitialLocale() {
        const locale: Locale = determineLocale();
        this.$root.$i18n.locale = locale;
        this.$vuetify.lang.current = locale;
    }

    private switchLocale(locale: Locale) {
        this.$root.$i18n.locale = locale;
        this.$vuetify.lang.current = locale;
        setLocale(locale);

        try {
            this.$router.push({
                name: this.$route.name ?? undefined,
                params: { locale }
            });
            // eslint-disable-next-line no-empty
        } catch {}
    }
}
