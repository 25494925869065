













import CompanySpecific from '@/mixins/CompanySpecific';
import { registrationState } from '@/store/modules/registration';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class Loading extends Mixins(CompanySpecific) {

    get isLoading(): boolean {
        return registrationState.isLoading;
    }
}
