import { FiltersVuexState } from '@/store/modules/filters';
import { RegistrationVuexState } from '@/store/modules/registration';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export interface RootVuexState {
    registration: RegistrationVuexState;
    filters: FiltersVuexState;
}

export default new Vuex.Store<RootVuexState>({
    strict: process.env.NODE_ENV !== 'production'
});
