import { filtersState } from '@/store/modules/filters';
import { EventFilters } from '@/types';
import { Company, Language } from '@/types/enums';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CompanySpecific extends Vue {

    @Prop({ type: String, required: true })
    public readonly company!: Company;

    get isSkyAutokool(): boolean {
        return this.company === Company.SKYAUTOKOOL;
    }

    get isLoppatme(): boolean {
        return this.company === Company.LOPPASTME;
    }

    get isSmart(): boolean {
        return this.company === Company.SMART_AUTOKOOL;
    }

    get companyEmail(): string {
        if (this.isSkyAutokool) {
            if (this.filtersVuex) {
                if (this.filtersVuex.language === Language.ENG) {
                    return 'skybestds@gmail.com';
                }
                // locationId: 2 - Lasnamäe
                if (this.filtersVuex.locationId === 2) {
                    return 'sky.lasnamae@gmail.com';
                }
            }
            return 'info@skyautokool.ee';
        } else if (this.isLoppatme) {
            return 'libedasoit@gmail.com';
        } else if (this.isSmart) {
            return 'autokoolsmart@gmail.com';
        } else {
            return 'info@skyautokool.ee';
        }
    }

    get companyPhone(): string {
        if (this.isSkyAutokool) {
            if (this.filtersVuex) {
                if (this.filtersVuex.language === Language.ENG) {
                    return '+372 5687 0125';
                }
                // locationId: 2 - Lasnamäe
                if (this.filtersVuex.locationId === 2) {
                    return '+372 5650 0845';
                }
            }
            return '+372 5802 8528';
        } else if (this.isLoppatme) {
            return '+372 5650 0845';
        } else if (this.isSmart) {
            return '+372 5802 8528';
        } else {
            return '+372 5802 8528';
        }
    }

    get color(): string {
        if (this.isSkyAutokool) {
            return 'primary';
        } else if (this.isLoppatme) {
            return 'secondary';
        } else if (this.isSmart) {
            return 'accent';
        } else {
            return 'primary';
        }
    }

    get border(): string {
        if (this.isSkyAutokool) {
            return 'sky-info-border';
        } else if (this.isLoppatme) {
            return 'libeda-info-border';
        } else if (this.isSmart) {
            return 'smart-info-border';
        } else {
            return 'sky-info-border';
        }
    }

    private get filtersVuex(): EventFilters | null {
        return filtersState.filters;
    }
}
