




















































import { saveRegistrationToDrivingLesson } from '@/api/registration';
import RegistrationByPersonalCodeFormDialog from '@/components/forms/RegistrationByPersonalCodeFormDialog.vue';
import RegistrationFormContainer from '@/components/forms/RegistrationFormContainer.vue';
import DatePicker from '@/components/utils/DatePicker.vue';
import CompanySpecific from '@/mixins/CompanySpecific';
import DateUtils from '@/mixins/DateUtils';
import RegistrationFormMixin from '@/mixins/RegistrationFormMixin';
import { RegistrationToDrivingLessonForm } from '@/types';
import { personalCodeRegex } from '@/utils/formValidators';
import axios from 'axios';
import { Component, Mixins } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { maxLength, required } from 'vuelidate/lib/validators';
import { Validate } from 'vuelidate-property-decorators';

@Component({
    components: { RegistrationFormContainer, RegistrationByPersonalCodeFormDialog, DatePicker },
    mixins: [validationMixin]
})
export default class RegistrationToDrivingLessonFormDialog extends Mixins(RegistrationFormMixin, CompanySpecific, DateUtils) {

    private lazyValidationEnabled: boolean = true;

    @Validate({
        personalCode: {
            required,
            maxLength: maxLength(255),
            personalCodeRegex
        },
        healthCertificateDoctorName: {
            required,
            maxLength: maxLength(64)
        },
        healthCertificateIssueDate: {
            required
        }
    })
    private registrationForm: RegistrationToDrivingLessonForm = {
        personalCode: null,
        healthCertificateDoctorName: null,
        healthCertificateIssueDate: null,
        confirmation: false
    }

    get personalCodeErrorMessages(): string[] {
        if (!this.$v.registrationForm.personalCode?.$dirty) return [];
        if (!this.$v.registrationForm.personalCode?.required) return ['Личный код не может быть пустым'];
        if (!this.$v.registrationForm.personalCode?.maxLength) return ['Личный код может быть максимум 255 символов'];
        if (!this.$v.registrationForm.personalCode?.personalCodeRegex) return ['Неверный личный код'];
        return [];
    }
    get healthCertificateDoctorNameErrorMessages(): string[] {
        if (!this.$v.registrationForm.healthCertificateDoctorName?.$dirty) return [];
        if (!this.$v.registrationForm.healthCertificateDoctorName?.required) return ['Имя врача не может быть пустым'];
        if (!this.$v.registrationForm.healthCertificateDoctorName?.maxLength) return ['Имя врача может быть максимум 64 символа'];
        return [];
    }
    get healthCertificateIssueDateErrorMessages(): string[] {
        if (this.lazyValidationEnabled) return [];
        if (!this.$v.registrationForm.healthCertificateIssueDate?.$dirty) return [];
        if (!this.$v.registrationForm.healthCertificateIssueDate?.required) return ['Дата выдачи не может быть пустой'];
        return [];
    }
    get confirmationErrorMessages(): string[] {
        if (this.lazyValidationEnabled) return [];
        if (!this.registrationForm.confirmation) return ['Необходимо подтверждение'];
        return [];
    }

    private async submit() {
        this.lazyValidationEnabled = false;
        this.$v.$touch();
        if (this.$v.$invalid || this.isSubmitting) {
            return;
        }

        this.isSubmitting = true;
        try {
            await saveRegistrationToDrivingLesson({
                personalCode: this.registrationForm.personalCode!,
                healthCertificateDoctorName: this.registrationForm.healthCertificateDoctorName!,
                healthCertificateIssueDate: this.registrationForm.healthCertificateIssueDate!,
                studyOption: this.studyOption,
                studyOptionId: this.event!.id as number
            });

            this.$gtm.trackEvent({ event: 'reg_autokool' });
            this.$notify({
                text: this.$t('form.success') as string,
                type: 'success'
            });
            this.reset();
        } catch (error: unknown) {
            if (axios.isAxiosError(error) && error.response?.data?.showFullForm) {
                this.$emit('showFullForm', this.registrationForm.personalCode!);
            }
        } finally {
            this.isSubmitting = false;
        }
    }

    private reset() {
        this.registrationForm = {
            personalCode: null,
            healthCertificateDoctorName: null,
            healthCertificateIssueDate: null,
            confirmation: false
        };
        this.lazyValidationEnabled = true;
        this.$v.$reset();

        this.dialog = false;
        this.$emit('input', false);
    }
}
